import { Route, Routes, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import Sidebar from "../components/Sidebar";
import { Table } from 'antd';
import axios from 'axios';
// import { useAuth } from "../hooks/AuthProvider";
import withProtectedPage from '../withProtectedPage';
import { fetchUserData } from '../api/FetchUser';

const columns = [
    {
      title: 'Student Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),

    },
    {
      title: 'Student Ranking',
      dataIndex: 'ranking',
      key: 'ranking',
      sorter: (a, b) => a.ranking - b.ranking,
    },
    {
      title: 'Quiz Average',
      dataIndex: 'quizAverage',
      key: 'quizAverage',
      sorter: (a, b) => a.quizAverage - b.quizAverage,
    },
  ];
  const data = [
    {
      key: '1',
      name: 'John Doe',
      ranking: 1,
      quizAverage: 85,
    },
    {
      key: '2',
      name: 'Jane Smith',
      ranking: 3,
      quizAverage: 92,
    },
    {
      key: '3',
      name: 'Bob Johnson',
      ranking: 2,
      quizAverage: 78,
    },
    
    // ...
  ];
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

const Students = (props) => {



  return (
    <div className="dashboard">
      <div className="SideMenu">
      <Sidebar setLoggedIn={props.setLoggedIn} />
      </div>
      <div className="dashboard__content">
      <div className="db_content">
      <h2>My Students</h2>
      <div className="sep"></div>

      <Table
  columns={columns}
  dataSource={data}
  onChange={onChange}
  pagination={{
    defaultCurrent: 1, // initial page number
    defaultPageSize: 10, // initial items per page
    total: data.length, // total number of items
    showSizeChanger: true, // show the page size changer
    showQuickJumper: true, // show the quick jumper (go to page input)
  }}
  showSorterTooltip={{
    target: 'sorter-icon',
  }}
/>


    
    </div>
       
      </div>
    </div>
  );
};

export default withProtectedPage(Students);