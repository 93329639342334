import React from 'react';
import './AnzanComponent.css';

const RyadTop = ({ value }) => {
  const validValue = value >= 5 ? 5 : 0;
  return (
    <div className="ryad top" style={{ display: 'block' }}>
      <div className="ryad-top">
        {validValue === 5 ? <div className="ukost metka"></div> : <div className="ukost"></div>}
      </div>
    </div>
  );
};

const RyadBot = ({ value }) => {
  let metkaIndex = value >= 5 ? value - 5 : Math.min(value, 3);
  return (
    <div className="ryad">
      <div className="ryad-bot">
        {Array.from({ length: 4 }, (_, i) => {
          const shouldAddMetka = i === metkaIndex;
          const isActive = metkaIndex === -1 || i > metkaIndex;
          return (
            <div
              key={i}
              className={`ukost ${isActive ? 'active' : ''} ${shouldAddMetka ? 'metka' : ''}`}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

const AnzanComponent = ({ numCards, difficulty, values = [] }) => {
  const numLines = difficulty === '1D' ? 1 : difficulty === '2D' ? 2 : difficulty === '3D' ? 3 : 1;

  // Split values into individual digits if the difficulty is 2D or 3D
  const splitValues = values.flatMap(value => {
    if (difficulty === '1D') return [value];
    return String(value).padStart(numLines, '0').split('').map(Number);
  });

  return (
    <div className='flex-center'>
      <div id="anzan">
        <div style={{ width: '100%', overflow: 'hidden', position: 'relative' }}>
          {Array.from({ length: numLines }, (_, lineIndex) => (
            <div key={lineIndex} className="AnzanLine">
              {Array.from({ length: numCards }, (_, cardIndex) => (
                <React.Fragment key={cardIndex}>
                  <RyadTop visible={true} value={splitValues[lineIndex + cardIndex * numLines] || 0} />
                  <RyadBot value={splitValues[lineIndex + cardIndex * numLines] || 0} />
                </React.Fragment>
              ))}
            </div>
          ))}
        </div>
        <div className="hline">
          <div className="tochka"></div>
        </div>
      </div>
    </div>
  );
};

export default AnzanComponent;
